import React from 'react';
import Image from 'next/image';

function TrustersList({ block }) {
	const trusters = block?.items;
	return (
		<>
			<div className="main">
				<div className="container">
					<div className="title">{block?.primary?.slice_label}</div>
					<div className="trusters_list">
						{trusters?.map((item, idx) => {
							const companyItem = item.company_logo_link;
							return (
								<div className="partner_logo_wrapper">
									<img draggable={false} 
										key={idx}
										className="partner_logo"
										src={companyItem.url}
										// alt={companyItem.alt || 'partner_logo'}
										// width={companyItem.dimensions.width}
										// height={companyItem.dimensions.height}
										// layout='fill'
										// objectFit='contain'
										loading="lazy"
									/>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<style jsx>{`
				.title {
					text-align: center;
					font-family: 'Roboto-Medium';
					font-size: 13px;
					font-weight: 600;
					line-height: 24px;
					letter-spacing: 0em;
					text-align: center;
					color: #949494;
					text-transform: uppercase;
				}
				.trusters_list {
					margin-top: 60px;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0 92px;
					flex-wrap: wrap;
					gap: 40px;
				}
				.main {
					background: #141414;
					padding: 64px 0;
				}
				.container {
					max-width: 1440px;
					margin: 0 auto;
					padding: 0 48px;
				}
				.partner_logo_wrapper {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 175px;
					max-width: 180px;
					height: auto;
					max-height: 120px;
				}
				.partner_logo {
					width: 100%;
					object-fit: contain;
					layout: fill;
				}
				@media (max-width: 640px) {
					.container {
						padding: 0px 16px;
					}
					.partner_logo_wrapper {
						width: 100%;
						max-height: 100px;
					}
					.trusters_list {
						margin-top: 32px;
						padding: 0 22px;
						display: grid;
						grid-template-columns: repeat(2, 1fr);
						gap: 10px 20px;
					}
					.main {
						padding: 32px 0 48px 0;
					}
				}
			`}</style>
		</>
	);
}

export default TrustersList;
